<template>
  <b-container fluid>
    <slot v-if="authUser.office_detail.org_id === 13">
      <priceGraphicalReport/>
    </slot>
    <slot v-else>
      <slot v-if="$store.state.Auth.activeRoleId === 1 || authUser.office_detail.org_id !== 16">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{  $t('cotton_report.cotton_production_dashboard') }}</h4>
            </template>
          <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('cotton_config.region')"
                        label-for="region_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.region_id"
                            :options="regionList"
                            id="region_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('cotton_config.zone')"
                        label-for="zone_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.zone_id"
                            :options="zoneList"
                            id="zone_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.district')"
                        label-for="district_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.district_id"
                            :options="districtList"
                            id="district_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.upazila')"
                        label-for="upazilla_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.upazilla_id"
                            :options="upazillaList"
                            id="upazilla_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('cotton_config.unit')"
                        label-for="unit_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.unit_id"
                            :options="unitList"
                            id="unit_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('cotton_config.cotton_variety')"
                        label-for="cotton_variety_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.cotton_variety_id"
                            :options="cottonVaritiesList"
                            id="cotton_variety_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('cotton_config.cotton_name')"
                        label-for="cotton_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.cotton_id"
                            :options="cottonNameList"
                            id="cotton_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.fiscal_year')"
                        label-for="fiscal_year_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-show="showData">
          <b-col md="12">
            <iq-card>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row mt-5>
                    <b-col md="12">
                      <!-- Apex Chart -->
                      <iq-card class="pt-2">
                        <template v-slot:body>
                          <b-row>
                            <b-col lg="12">
                              <b-overlay class="border shadow p-2" :show="loading">
                                <h5 class="text-center">{{ $t('cotton_report.cotton_production_dashboard')}}</h5>
                                <apexchart type="bar" height="350" :options="chartOptions" :series="series()"></apexchart>
                              </b-overlay>
                            </b-col>
                          </b-row>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </slot>
      <iq-card v-if="$store.state.Auth.activeRoleId === 1 || authUser.office_detail.org_id === 16">
        <template v-slot:headerTitle>
          <h4 class="card-title">{{  $t('e_pusti_config.e_pust_campaign_dashboard') }}</h4>
        </template>
        <template v-slot:body>
          <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('e_pusti_config.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search1.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-button type="button" variant="primary" @click="searchData1">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
          </b-row>
        </template>
      </iq-card>
      <b-row v-show="showData1">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row mt-5>
                  <b-col md="12">
                    <!-- Apex Chart -->
                    <iq-card class="pt-2">
                      <template v-slot:body>
                        <b-row>
                          <b-col lg="12">
                            <b-overlay class="border shadow p-2" :show="loading">
                              <h5 class="text-center">{{ $t('e_pusti_config.e_pust_campaign_dashboard')}}</h5>
                              <apexchart type="bar" height="350" :options="chartOptions1" :series="series1()"></apexchart>
                            </b-overlay>
                          </b-col>
                        </b-row>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </slot>
  </b-container>
</template>
<script>
import priceGraphicalReport from '../../../crop-price-info/reports/pages/price-graphical-report/List'
import { core } from '@/config/pluginInit'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { CottonProductionDashboard, campaignDashboard } from '../../api/routes'
import { getCottonMarketUserInfo, getUnitAssignedParams } from '@/Utils/data-filtering-by-priviledge'

export default {
  components: {
    priceGraphicalReport
  },
  data () {
    return {
      showData: false,
      showData1: false,
      search: {
        region_id: 0,
        zone_id: 0,
        district_id: 0,
        upazilla_id: 0,
        unit_id: 0,
        fiscal_year_id: 0,
        cotton_variety_id: 0,
        cotton_id: 0
      },
      search1: {
        fiscal_year_id: 0
      },
      zoneList: [],
      districtList: [],
      upazillaList: [],
      unitList: [],
      seriesData: [0, 0, 0],
      seriesData1: [0, 0, 0, 0],
      loading: false,
      loading1: false,
      // ,
      // cottonMarketUserInfo: {
      //   regionList: [],
      //   zoneList: [],
      //   districtList: [],
      //   upazilaList: []
      // }
      cottonNameList: []
    }
  },
  computed: {
    cottonMarketUserInfo () {
      return getCottonMarketUserInfo()
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalYearList.map((item) => {
          return { value: item.value, text: item.text_en }
      })
    },
    regionList: function () {
      // return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
      return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
    },
    // districtList: function () {
    //   return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    // },
    cottonVaritiesList: function () {
        const ObjectData = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
        return ObjectData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    // cottonNameList: function () {
    //     const ObjectData = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.status === 1)
    //     return ObjectData.map(item => {
    //         if (this.$i18n.locale === 'bn') {
    //             return { value: item.value, text: item.text_bn }
    //         } else {
    //             return { value: item.value, text: item.text }
    //         }
    //     })
    // },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    chartOptions () {
      return {
        chart: {
          height: 450,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            },
            columnWidth: '30%',
            distributed: true
          }
        },
        // colors: ['#ad160a'],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -1,
          style: {
            fontSize: '14px',
            colors: ['#fff']
          }
        },
        xaxis: {
          categories: [this.$t('cotton_report.production_quantity'), this.$t('cotton_report.sell_quantity'), this.$t('cotton_report.remaining_stock')],
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    chartOptions1 () {
      return {
        chart: {
          height: 450,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            },
            columnWidth: '30%',
            distributed: true
          }
        },
        // colors: ['#4e7d09'],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '14px',
            colors: ['#000']
          }
        },
        xaxis: {
          categories: [this.$t('e_pusti_config.total_campaign'), this.$t('e_pusti_config.request_campaign'), this.$t('e_pusti_config.complete_campaign'), this.$t('e_pusti_config.pending_campaign')],
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }
    // ,
    // isUnitAssignedUser () {
      // const authUser = this.$store.state.Auth.authUser
      // const activeRoleId = this.$store.state.Auth.activeRoleId
      // const userOfficeTypeObj = this.$store.state.commonObj.officeTypeList.find(item => item.value === parseInt(authUser.office_type_id))
      // return !(activeRoleId === 1 || authUser.is_org_admin || userOfficeTypeObj.text_en.toLowerCase() === 'head office')
    // }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal) {
        this.zoneList = this.getZoneList(this.search.region_id)
        this.upazillaList = this.getUpazillaList(this.search.district_id)
      }
    },
    'search.cotton_variety_id': function (newVal, oldVal) {
      this.cottonNameList = this.getCottonNameList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.zoneList = this.getZoneList(newVal)
    },
    'search.zone_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazillaList = this.getUpazillaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unitList = this.getUnitList(newVal)
    },
    cottonMarketUserInfo: function (newVal) {
      this.setUserData()
    }
  },
  created () {
    // this.loadData()
    this.showData = true
    // this.cottonMarketUserInfo = await getCottonMarketUserInfo()
    this.setUserData()
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    series () {
      return [
        {
          data: this.seriesData
        }
      ]
    },
    series1 () {
      return [
        {
          data: this.seriesData1
        }
      ]
    },
    searchData () {
      this.loadData()
      this.showData = true
    },
    async loadData () {
        this.loading = true
        const unitAssignedParams = getUnitAssignedParams()
        const params = Object.assign({}, this.search, {
          region_id: this.search.region_id,
          zone_id: this.search.zone_id,
          district_id: this.search.district_id,
          upazilla_id: this.search.upazilla_id,
          cotton_variety_id: this.search.cotton_variety_id,
          cotton_id: this.search.cotton_id,
          fiscal_year_id: this.search.fiscal_year_id
        }, unitAssignedParams)
        await RestApi.getData(agriMarketingServiceBaseUrl, CottonProductionDashboard, params).then(response => {
            if (response.success) {
                if (response.data.length) {
                    setTimeout(() => {
                        this.seriesData = response.data
                    }, 1000)
                } else {
                    this.seriesData = [0, 0, 0]
                }
            } else {
              this.seriesData = [0, 0, 0]
            }
        })
        this.loading = false
    },
    searchData1 () {
        if (this.search1.fiscal_year_id !== 0) {
            this.loadData1()
            this.showData1 = true
        } else {
            this.$toast.error({
                title: 'Error',
                message: 'Please select Fiscal Year'
            })
        }
    },
    async loadData1 () {
        this.loading1 = true
        const params = Object.assign({}, this.search1, {
            fiscal_year_id: this.search1.fiscal_year_id
        })
        await RestApi.getData(agriMarketingServiceBaseUrl, campaignDashboard, params).then(response => {
          if (response.success) {
            if (response.data.length) {
              setTimeout(() => {
                this.seriesData1 = response.data
              }, 1000)
            } else {
              this.seriesData1 = [0, 0, 0, 0]
            }
          } else {
            this.seriesData1 = [0, 0, 0, 0]
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.loading = false
    },
    getCottonNameList (cottonVarietyId) {
      return this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.status === 1 && item.cotton_variety_id === parseInt(cottonVarietyId))
    },
    getZoneList (regionId = null) {
        // return this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
        return this.cottonMarketUserInfo.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
    },
    getDistrictList (id) {
      const obj = this.cottonMarketUserInfo.zoneList.find(item => item.value === parseInt(id))
      const districtIdArr = obj.district_ids ? JSON.parse(obj.district_ids) : []
      return this.cottonMarketUserInfo.districtList.filter(item => districtIdArr.includes(parseInt(item.value)))
    },
    getUpazillaList (districtId = null) {
      // const upazillaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const upazillaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazillaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazillaList
    },
    getUnitList (upazilaId) {
      return this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.upazilla_id === parseInt(upazilaId))
    },
    setUserData () {
      if (this.cottonMarketUserInfo.regionList.length === 1) {
        this.search.region_id = this.cottonMarketUserInfo.regionList[0].value
      }
      if (this.cottonMarketUserInfo.zoneList.length === 1) {
        this.search.zone_id = this.cottonMarketUserInfo.zoneList[0].value
      }
      if (this.cottonMarketUserInfo.districtList.length === 1) {
        this.search.district_id = this.cottonMarketUserInfo.districtList[0].value
      }
      if (this.cottonMarketUserInfo.upazilaList.length === 1) {
        this.search.upazilla_id = this.cottonMarketUserInfo.upazilaList[0].value
      }
      if (this.cottonMarketUserInfo.unitList.length === 1) {
        this.search.unit_id = this.cottonMarketUserInfo.unitList[0].value
      }
      // this.loadData()
    }
  }
}
</script>
