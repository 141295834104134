export const list = '/list'
export const store = '/store'
export const update = '/update'
export const toggleStatus = '/toggle-status'
export const destroy = '/delete'

const irrigationDashboardPrefix = '/irrigation-dashboard'
export const fetchScheme = irrigationDashboardPrefix + '/fetch/scheme'
export const fetchTotal = irrigationDashboardPrefix + '/fetch/total'
export const fetchComplain = irrigationDashboardPrefix + '/fetch/complain'
export const fetchVisitor = irrigationDashboardPrefix + '/fetch/visitor'
export const CottonProductionDashboard = '/cotton/reports/cotton-production/dashboard'
export const campaignDashboard = '/e-pusti/campaign-dashboard/list'
